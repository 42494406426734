<template>
  <div id="app">
    <div class="logo">
      <img src="./assets/office.png" />
    </div>
    <div class="title">
      <h1>KaminoStudio</h1>
      <h2>Microsoft Office Apps</h2>
    </div>
    <div class="link">
      <van-row>
        <van-col span="8">
          <van-button
            type="primary"
            tag="a"
            href="https://portal.office.com/"
            target="_blank"
            >Office 主页</van-button
          >
        </van-col>
        <van-col span="8">
          <van-button
            type="primary"
            tag="a"
            href="https://outlook.office365.com/?realm=imea.me"
            target="_blank"
            >Outlook 邮箱</van-button
          ></van-col
        >
        <van-col span="8">
          <van-button
            type="primary"
            tag="a"
            href="https://scwlgzs-my.sharepoint.com/"
            target="_blank"
            >Onedrive 网盘</van-button
          ></van-col
        >
      </van-row>
      <p class="tip">如果登录时提示选择账户类型，选择 "工作或学校账户"。</p>
    </div>
    <div class="guide">
      <van-tabs v-model="active">
        <van-tab name="web" title="网页版">
          <div class="guide-content">
            <p>1. 点击上面的 "Office 主页" 按钮进入登录页面。</p>
            <p>
              2. 输入邮箱地址后点击下一步，如果需要选择账户类型，点击
              "工作或学校账户"。
            </p>
            <img src="./assets/web-2.png" alt="" />
            <p>3. 输入密码后点击登录，按照需要选择是否保持登录状态。</p>
            <img src="./assets/web-4.png" alt="" />
            <p>4. 登录成功后可以使用 Microsoft Office 网页版。</p>
            <img src="./assets/web-5.png" alt="" />
          </div>
        </van-tab>
        <van-tab name="desktop" title="桌面版">
          <div class="guide-content">
            <h3>安装 Microsoft Office 桌面版</h3>
            <p>Mac 版在应用商店中搜索 Office 安装。</p>
            <p>
              Windows 版按照网页版说明登录主页后，点击右上角 "安装 Office
              按钮"，选择 "Office365 应用" 下载在线安装程序。
            </p>
            <img src="./assets/pc-win-1.png" alt="" />
            <p>
              Windows 版也可以使用<a
                href="https://otp.landian.vip/zh-cn/"
                target="_blank"
                >第三方部署工具</a
              >、ISO安装包进行安装。
            </p>
            <h3>激活 Microsoft Office</h3>
            <p>
              安装成功后打开任意 Microsoft Office 软件，在 "登录以激活"
              页面点击登录。
            </p>
            <p>
              如果没有 "登录以激活" 页面，选择左侧账户然后点击 "激活产品" 按钮。
            </p>
            <img src="./assets/pc-1.png" alt="" />
            <p>
              输入账号下一步后，如果需要选择账户类型，点击 "工作或学校账户"。
            </p>
            <img src="./assets/pc-3.png" alt="" />
            <p>输入密码登录后，按照提示选择仅登录该应用，然后即可激活成功。</p>
            <img src="./assets/pc-5.png" alt="" />
          </div>
        </van-tab>
        <van-tab name="mobile" title="移动版">
          <div class="guide-content">
            <h3>安装 Microsoft Office 移动版</h3>
            <p>
              在应用商店搜索安装 Microsoft Office 或 Microsoft Word、Microsoft
              Excel 等应用程序。
            </p>
            <h3>登录 Microsoft Office 移动版</h3>
            <p>登录时如果需要选择账户类型，选择 "工作或学校账户"。</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      active: "web",
    };
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  max-width: 600px;
  margin: 0 auto;
}

.logo {
  text-align: center;
}

.logo img {
  width: 160px;
  height: 160px;
}

.tip{
  font-size: 12px;
  text-align: center;
}
.guide-content {
  max-width: 560px;
  margin: 0 20px;
}

.guide-content h3 {
  text-align: left;
}

.guide-content a {
  color: #000000;
}

.guide-content p {
  text-align: left;
}

.guide-content img {
  width: 90%;
  margin: 0 auto;
}
</style>
